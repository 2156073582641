<template>
  <footer v-editable="config" :data-theme="pageTheme">
    <div class="container container--bleed">
      <div class="row row--full">
        <div class="footer-logo">
          <a href="/" title="Kudosity Logo" v-if="logo?.filename">
            <NuxtImg provider="storyblok" width="218" height="48" loading="lazy" :src="logo?.filename" alt="Kudosity Logo" />
          </a>
        </div>
      </div>
      <div class="row row--full footer-menu-row">
        <div class="footer-menu-group" v-for="(group, g) in config.footer_menu" :key="g" v-editable="group">
          <div class="footer-menu" v-for="(menu, m) in group.items" :key="m" v-editable="menu">
            <template v-if="menu?.label">
              <a v-if="menu?.link?.cached_url" :href="getLinkFieldUrl(menu?.link)" class="footer-menu-label" v-text="menu.label" :target="menu?.link?.target"></a>
              <div v-else class="footer-menu-label" v-text="menu.label"></div>
            </template>
            <ul class="footer-menu-list">
              <li v-for="(link, l) in menu.menu" :key="l" class="footer-link" v-editable="link">
                <a :href="getLinkFieldUrl(link.link)" v-text="link.label"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row row-full">
        <div class="footer-sub">
          <div class="footer-social-links" v-if="config.social_links">
            <a :href="getLinkFieldUrl(link.url)" v-for="(link, l) in config.social_links" :key="l" class="footer-social-link" v-editable="link" title="`Kudosity ${link.icon} URL`">
              <img loading="lazy" :src="`/icons/icon-${link.icon}-${theme}.svg`" :alt="`Social Icon ${link.icon}`" height="24" width="24" />
            </a>
          </div>
          <div class="footer-copyright" v-text="config.copyright"></div>
          <div class="footer-submenu" v-if="config.footer_submenu">
            <a :href="getLinkFieldUrl(link.link)" v-for="(link, l) in config.footer_submenu" :key="l" class="footer-submenu-link" v-text="link.label" v-editable="link" :data-countries="link.showOnlyOnCountries || null"> </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style src="~/assets/scss/layout/footer.scss" lang="scss"></style>
<script setup>
  import { usePageStore } from "@/stores/page";

  const { getCountryInfo } = useGeoLocation();
  const props = defineProps({
    config: {
      type: Object,
    },
  });

  const pageStore = usePageStore();
  const theme = computed(() => pageStore.footer_theme);

  const pageTheme = ref("dark");

  watch(
    pageStore,
    async (state) => {
      await nextTick();
      pageTheme.value = state.footer_theme;
      if (document) document?.querySelector("footer")?.setAttribute("data-theme", state.footer_theme);
    },
    { deep: true }
  );

  const logo = computed(() => {
    if (theme.value === "light") {
      return props.config.footer_logo_dark;
    }
    return props.config.footer_logo;
  });

  const showLinksByCountry = (country) => {
    document.querySelectorAll("footer .footer-submenu-link[data-countries]").forEach((el) => {
      const countries = el.getAttribute("data-countries");

      if (countries) {
        const countryList = countries.split(",");
        if (countryList.includes(country)) {
          el.style.display = "block";
        }
      }
    });
  };

  onMounted(() => {
    if (import.meta.client) {
      requestAnimationFrame(() => {
        const countryInfo = getCountryInfo();

        if (countryInfo?.country?.code) {
          document.querySelector("html").setAttribute("data-country", countryInfo?.country?.code);
          showLinksByCountry(countryInfo?.country?.code);
        }
      });
    }
  });
</script>
