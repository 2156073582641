export const useGeoLocation = () => {
  const fetchCountryInfo = async (): Promise<any> => {
    try {
      const response = await fetch("/.netlify/functions/info");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      sessionStorage.setItem("kudosity-geo", JSON.stringify(data));
      return data;
    } catch (error) {
      console.warn("Could not fetch country code", error);
      const defaultData = { country: { name: "Unknown", code: "-" } };
      sessionStorage.setItem("kudosity-geo", JSON.stringify(defaultData));
      return defaultData;
    }
  };

  const getCountryInfo = (): any => {
    const storedData = sessionStorage.getItem("kudosity-geo");
    if (storedData) {
      return JSON.parse(storedData);
    } else {
      return fetchCountryInfo();
    }
  };

  return {
    getCountryInfo,
  };
};
